
import { reactive, ref, toRefs, defineComponent, onMounted, watch, nextTick } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { debounce, throttle } from 'lodash';
import i18n from '@/locales';
import Killtime from '@/components/killtime/index.vue';
import Killtime2 from '@/components/killtime2/index.vue';
import {
    regionList,
    delRegion,
    editRegion,
    createRegion,
    regionInfo,
    roomList,
    createTasks,
    Operationtasks,
    deletetaskrw,
    currenttask,
    newroomlist,
    getmjpmac,
    robotelevator
} from '@/apis/region';
import { getAllUser, addUser } from '@/apis/user';
import internal from 'stream';
import { useStore } from '@/store';
import { ElMessage } from 'element-plus';
import { error } from 'console';
interface stateface {
    paramshttp?: Object;
    //定义点击房间后传递的字段为对象
    roomsobj: any;
    options: Array<any>;
    radio1: String;
    checked1: Boolean;
    checked2: Boolean;
    countdown: any;
    Theinvasion: any;
    selectcountdown: Array<String>;
    selectTheinvasion: Array<String>;
    num: any;
    explonum: any;
    sn: any;
    checked1falge: Boolean;
    exploresion: string | number;
    getfloor: number;
    getfloorarray: Array<number>;
    getflooron: boolean;
    mode: number;
    addoperatorflge: boolean;
    operatorname: any;
    opertorlist: Array<any>;
    opeatorfalge: boolean;
    addopeatorsname: string;
    explonum2: any;
    exploresion2: string | number;
    // count:any,
    // counts: any
}
// interface resQusts{
//   duration:Number
// }
export default defineComponent({
    components: {
        Killtime,
        Killtime2
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const states: stateface = reactive({
            sn: route.params.sn,
            paramshttp: {
                page: 1,
                pageSize: 100,
                limit: true,
                areaName: ''
            },
            value: [],
            options: [],
            roomsobj: {},
            radio1: '',
            checked1: true,
            checked2: true,
            selectcountdown: ['5s', '10s', '15s', '20s', '25s', '30s'],
            countdown: '20s',
            selectTheinvasion: ['1min', '3min', '5min', '10min'],
            explorelist: ['30s', '1min', '2min', '3min', '5min'],
            exploresion: '',
            Theinvasion: '5min',
            num: 5,
            explonum: null,
            checked1falge: false,
            getfloor: 1,
            getfloorarray: [],
            getflooron: false,
            mode: 1,
            addoperatorflge: false,
            opertorlist: [],
            operatorname: store.state.user.name == '' ? store.state.user.email : store.state.user.name,
            opeatorfalge: false,
            addopeatorsname: '',
            explonum2: null,
            exploresion2: ''
            // count: {abc:1},
            // counts: {}
            // Gotothetop() {
            //     router.go(-1)
            // }
        });
        const methods = reactive({
            // 新增操作人按钮确认
            addUseropertaor() {
                if (states.addopeatorsname.length < 6 || states.addopeatorsname.length > 20) {
                    ElMessage({
                        message: i18n.global.t('user.usernameRule2'),
                        type: 'error'
                    });
                    return;
                }
                addUser({ username: states.addopeatorsname, password: '123456' }).then((res: any) => {
                    console.log(res);
                    if (res.code == 200) {
                        states.opeatorfalge = false;
                        states.operatorname = states.addopeatorsname;
                        states.addopeatorsname = '';
                        methods.getusers();
                        ElMessage({
                            message: i18n.global.t('system.createSuccess'),
                            type: 'success'
                        });
                    } else if (res.code == 100013) {
                        ElMessage({
                            message: i18n.global.t('user.repeatUser'),
                            type: 'error'
                        });
                    }
                });
            },
            // 取消新增操作人
            cancelDialog() {
                states.opeatorfalge = false;
                states.addopeatorsname = '';
            },
            // 点击后设置操作人
            setusernames(ele: any) {
                if (ele.negative == 1) {
                    return;
                }
                states.operatorname = ele.username == '' ? ele.email : ele.username;
                states.addoperatorflge = false;
            },
            getelevator(ele: string) {
                robotelevator({
                    sn: states.sn,
                    name: ele
                }).then((res: any) => {
                    console.log(JSON.parse(res.data));
                    let ress = JSON.parse(res.data);
                    if (ele === 'ele_floor') {
                        methods.getfloorsmax(Number(ress.content.value));
                    } else {
                        states.getflooron = ress.content.value === 'on' ? true : false;
                    }
                });
            },
            godashboards() {
                router.push('/dashboard');
            },
            gotorobotsn() {
                router.push('/robot/robotInfo/' + states.sn);
            },
            killtrue(): void {
                Killtimes.value.timeflage = true;
            },
            killtrue2(): void {
                Killtimes2.value.timeflage = true;
            },
            timesvaluedurion(value1: number, value2: number) {
                states.explonum = value1 * 60 + value2;
                states.exploresion = value1 + 'min' + value2 + 'S';
                console.log(states.explonum);
            },
            timesvaluedurion2(value1: number, value2: number) {
                states.explonum2 = value1 * 60 + value2;
                states.exploresion2 = value1 + 'min' + value2 + 'S';
            },
            // 获取门禁牌是否绑定
            getchecked1falge(): void {
                getmjpmac({
                    sn: route.params.sn,
                    option: 'connected'
                }).then((res: any) => {
                    if (res.data.mac === '') {
                        states.checked1falge = true;
                        states.checked1 = false;
                    }
                });
            },
            // 获取区域及区域下的所有房间 手动添加上value 和label字段
            getquyus: (): void => {
                regionList(states.paramshttp).then((res: any) => {
                    console.log(res.data.lists);
                    states['options'] = res.data.lists;
                    states['options'].forEach((ele: any, index: number) => {
                        states['options'][index].value = states['options'][index].ID;
                        states['options'][index].label = states['options'][index].area_name;
                        newroomlist({
                            page: 0,
                            pageSize: 100,
                            sn: states.sn,
                            areaid: states['options'][index].ID
                        }).then((ress: any) => {
                            console.log(JSON.parse(ress.data));
                            states['options'][index]['children'] = [];
                            if (JSON.parse(ress.data).content.list.length > 0) {
                                JSON.parse(ress.data).content.list.forEach((eless: any, indexs: number) => {
                                    states['options'][index]['children'][indexs] = eless;
                                    states['options'][index]['children'][indexs].label = eless.name;
                                    states['options'][index]['children'][indexs].value = {
                                        roomid: eless.area_id === 0 ? eless.id : eless.yun_id,
                                        areaid: eless.area_id,
                                        mapnamas: eless.map
                                    };
                                });
                            }
                        });
                    });
                });
            },
            // 选择房间后判断
            handleChange: (value: any): void => {
                // states .roomsobj  = {ajda:1}
                if (!value[1]) {
                    ElMessage({
                        message: i18n.global.t('disinfect.dqmyfj')
                    });
                } else {
                    states.roomsobj = value[1];
                }
            },
            stabilization: debounce((Needtooffer) => Needtooffer(), 2000, {
                leading: true,
                trailing: false
            }),
            // 点击切换倒计时
            countselet: (values: string) => {
                console.log(values);
                states.countdown = values;
            },
            // 点击切换探索模式下时间
            exploresionselet: (explorevalue: string) => {
                console.log(explorevalue);
                if (parseInt(explorevalue) != 30) {
                    states.explonum = parseInt(explorevalue) * 60;
                } else {
                    states.explonum = parseInt(explorevalue);
                }
            },
            // 点击切换入侵恢复时间
            Theinvasionselet: (values: string) => {
                console.log(values);
                states.Theinvasion = values;
            },
            newtasks: () => {
                const restask: Object = {
                    mode: states.mode,
                    area_id: states.roomsobj.areaid,
                    auto_finish: parseInt(states.Theinvasion) * 60,
                    countdown: parseInt(states.countdown),
                    cycle: 'immediately',
                    days: [],
                    duration: states.radio1 === 'disinfect' ? states.explonum2 : states.radio1 === 'explore_disinfect' ? states.explonum : null,
                    flags: (states.checked1 ? 1 : 0) | (states.checked2 ? 2 : 0),
                    map_name: states.radio1 === 'deploy_disinfect' ? states.roomsobj.mapnamas : '',
                    room_id: states.roomsobj.roomid,
                    type: states.radio1,
                    time: '',
                    date: '',
                    destination: String(states.getfloor),
                    operator: states.operatorname
                };
                console.log(restask);
                createTasks(route.params.sn, restask).then((res: any) => {
                    // console.log(res)
                    Operationtasks(res.data.taskid, 'start', states.sn, {})
                        .then((ress: any) => {
                            console.log(ress);
                            if (JSON.parse(ress.data).content.code === 0) {
                                ElMessage({
                                    message: i18n.global.t('creted.cjcgzztz'),
                                    type: 'success'
                                });
                                currenttask(states.sn, {}).then((taskres: any) => {
                                    console.log(JSON.parse(taskres.data.data.statusinfo).content);
                                    if (JSON.parse(taskres.data.data.statusinfo).content.code === 0) {
                                        setTimeout(() => {
                                            router.replace(`/robot/taskInfo/${JSON.parse(taskres.data.data.statusinfo).content.report}`);
                                        }, 1000);
                                    }
                                });
                            } else if (JSON.parse(ress.data).content.code === 11010031) {
                                ElMessage({
                                    message: i18n.global.t('creted.mjpwljcjsb')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010023) {
                                ElMessage({
                                    message: i18n.global.t('creted.dqyjxrw')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010028) {
                                ElMessage({
                                    message: i18n.global.t('creted.xtlbcwcjsb')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010032) {
                                ElMessage({
                                    message: i18n.global.t('creted.sbgzcjsb')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010030) {
                                ElMessage({
                                    message: i18n.global.t('creted.jqjtqbcan')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010033) {
                                ElMessage({
                                    message: i18n.global.t('creted.dcyccjsb')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010034) {
                                ElMessage({
                                    message: i18n.global.t('creted.fztyccjsb')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010035) {
                                ElMessage({
                                    message: i18n.global.t('creted.dpdjglcjsb')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010036) {
                                ElMessage({
                                    message: i18n.global.t('creted.zdyccjsb')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010037) {
                                ElMessage({
                                    message: i18n.global.t('creted.zczcjsb')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010038) {
                                ElMessage({
                                    message: i18n.global.t('creted.xczcjsb')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010042) {
                                ElMessage({
                                    message: i18n.global.t('creted.dqdtbczwfcj')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010045 || JSON.parse(ress.data).content.code === 11011018) {
                                ElMessage({
                                    message: i18n.global.t('creted.dhmkyxzcjsb')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010046) {
                                ElMessage({
                                    message: i18n.global.t('creted.cgqyccjsb')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010048) {
                                ElMessage({
                                    message: i18n.global.t('creted.jqrwdggcjsb')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010049) {
                                ElMessage({
                                    message: i18n.global.t('creted.jqrdlxy')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else {
                                deletetaskrw(res.data.taskid, {});
                            }
                        })
                        .catch((error: any) => {
                            deletetaskrw(res.data.taskid, {});
                        });
                });
            },
            getfloorsmax(valsa: number) {
                for (let i = 1; i <= valsa; i++) {
                    states.getfloorarray.push(i);
                }
            },
            setfloorcs(values: number) {
                states.getfloor = values;
                console.log(states.getfloor);
            },
            getusers() {
                getAllUser({
                    userName: '',
                    authority: '',
                    negative: '',
                    pageSize: 1000,
                    page: 1
                }).then((res: any) => {
                    console.log(res);
                    states.opertorlist = res.data.lists;
                });
            }
        });
        let Killtimes = ref<any | HTMLElement>(null);
        let Killtimes2 = ref<any | HTMLElement>(null);
        // watch(() => states.count, (newValue, oldValue) => {
        //   console.log(newValue, oldValue)
        //   if (newValue != states.counts) {
        //   }
        // })
        onMounted(() => {
            //调用区域
            methods.getchecked1falge();
            methods.getquyus();
            methods.getelevator('elevator');
            methods.getelevator('ele_floor');
            methods.getusers();
            // console.log(store.state.user.authority)
            // states.count = {
            //   abc:2
            // }
            // states.counts = states.count
            nextTick(() => {
                console.log(Killtimes.value.timeflage);
                states.explonum = Killtimes.value.overindex * 60 + Killtimes.value.overmiaoindex;
                states.exploresion = Killtimes.value.overindex + 'min' + Killtimes.value.overmiaoindex + 'S';

                states.explonum2 = Killtimes2.value.overindex * 60 + Killtimes2.value.overmiaoindex;
                states.exploresion2 = Killtimes2.value.overindex + 'min' + Killtimes2.value.overmiaoindex + 'S';
            });
        });
        return {
            ...toRefs(states),
            ...toRefs(methods),
            store,
            debounce,
            Killtimes,
            Killtimes2
        };
    }
});
