<template>
    <div class="createstask">
        <el-breadcrumb separator="/" class="breadcrumb">
            <!-- <el-breadcrumb-item
        :to="{path: '/dashboard'}"
      > -->
            <el-breadcrumb-item @click="godashboards" class="needGo">
                {{ $t('route.robot') }}
            </el-breadcrumb-item>
            <!-- <el-breadcrumb-item
        :to="{path: '/robot/robotInfo/' + sn}"
      > -->
            <el-breadcrumb-item @click="gotorobotsn" class="needGo">
                {{ $t('robot.detail') }}
            </el-breadcrumb-item>
            <el-breadcrumb-item>{{ $t('creted.renwu') }}</el-breadcrumb-item>
        </el-breadcrumb>
        <el-breadcrumb separator="/" class="breadcrumb">
            <el-breadcrumb-item>{{ $t('creted.renwu') }}</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- <div class="createdtop">创建任务</div> -->
        <div class="contentWrapper robotDetail">
            <div class="">{{ $t('disinfect.room') }}:</div>
            <div class="Uniformmargmintop">
                <el-cascader v-model="value" :options="options" @change="handleChange" size="mini" style="width:300px" />
            </div>
            <div class="Uniformmargtop" v-show="roomsobj.roomid">{{ $t('disinfect.pattern') }}:</div>
            <div class="Uniformmargmintop" v-show="roomsobj.roomid">
                <el-radio v-model="radio1" v-show="roomsobj.mapnamas" label="deploy_disinfect">
                    {{ $t('disinfect.existingMap') }}
                </el-radio>
                <el-radio v-model="radio1" label="explore_disinfect">
                    {{ $t('creted.zztansuo') }}
                </el-radio>
                <el-radio v-model="radio1" label="disinfect">
                    {{ $t('creted.ydxs') }}
                </el-radio>
            </div>
            <div class="Uniformmargtop" v-show="radio1">
                {{ $t('user.operator') }}
            </div>
            <div class="addlistoperator" v-show="radio1" @click="addoperatorflge = !addoperatorflge">
                {{ operatorname }}
                <img
                    class="opertorimages"
                    :class="{
                        arrowTransform: addoperatorflge,
                        arrowTransformReturn: !addoperatorflge
                    }"
                    src="@/assets/images/robot/xiala.png"
                />
                <div class="addoperator" v-show="addoperatorflge">
                    <div class="modoals">
                        <div
                            class="boxdiv"
                            v-for="(ele, index) in opertorlist"
                            :key="index"
                            :style="{
                                color: ele.negative != 1 ? (ele.username == operatorname || ele.email == operatorname ? '#1890FF' : '') : 'rgba(0,0,0,0.2)',
                                cursor: ele.negative != 1 ? '' : 'no-drop'
                            }"
                            @click.stop="setusernames(ele)"
                        >
                            {{ ele.username == '' ? ele.email : ele.username }}
                        </div>
                    </div>
                    <div class="addbtn" @click="opeatorfalge = true" v-show="store.state.user.authority == 1">+ {{ $t('user.adduser') }}</div>
                </div>
            </div>
            <div class="Uniformmargtop" v-show="radio1 === 'deploy_disinfect' && getflooron">
                梯控楼层:
            </div>
            <div class="Uniformmargmintop" v-show="radio1 === 'deploy_disinfect' && getflooron">
                <el-select v-model="getfloor" placeholder="1" size="mini" @change="setfloorcs" style="width:300px">
                    <el-option v-for="item in getfloorarray" :key="item" :label="item" :value="item" />
                </el-select>
            </div>
            <div v-show="radio1 === 'disinfect'" class="Uniformmargtop">{{ $t('creted.xssj') }}:</div>
            <div v-show="radio1 === 'disinfect'" class="Uniformmargmintop">
                <div class="timesclass" @click="killtrue2">{{ exploresion2 }}</div>
                <Killtime2 ref="Killtimes2" @timesvaluedurion2="timesvaluedurion2" />
            </div>
            <div class="Uniformmargtop" v-show="radio1 === 'explore_disinfect'">{{ $t('creted.ddxssj') }}:</div>
            <div class="Uniformmargmintop" v-show="radio1 === 'explore_disinfect'">
                <div class="timesclass" @click="killtrue">{{ exploresion }}</div>
                <Killtime ref="Killtimes" @timesvaluedurion="timesvaluedurion" />
            </div>
            <div class="Uniformmargtop" v-show="radio1">{{ $t('creted.aqjc') }}:</div>
            <div class="Uniformmargmintop" v-show="radio1">
                <el-checkbox v-model="checked1" :disabled="checked1falge">
                    {{ $t('creted.mjp') }}
                </el-checkbox>
                <el-checkbox v-model="checked2">
                    PIR
                </el-checkbox>
            </div>
            <div class="Uniformmargtop" v-show="radio1 && radio1 !== 'disinfect'">{{ $t('creted.openuv') }}:</div>
            <div class="Uniformmargmintop" v-show="radio1 && radio1 !== 'disinfect'">
                <el-radio v-model="mode" :label="1">{{ $t('creted.openuvOn') }}</el-radio>
                <el-radio v-model="mode" :label="2">{{ $t('creted.openuvOff') }}</el-radio>
                <div :style="{ color: 'red', 'margin-top': '6px' }">
                    {{ $t('creted.openuvwarning') }}
                </div>
            </div>
            <div class="Uniformmargtop" v-show="radio1">{{ $t('robot.countdown') }}:</div>
            <div class="Uniformmargmintop" v-show="radio1">
                <el-select v-model="countdown" placeholder="20s" size="mini" @change="countselet" style="width:300px">
                    <el-option v-for="item in selectcountdown" :key="item" :label="item" :value="item" />
                </el-select>
            </div>
            <div class="Uniformmargtop" v-show="radio1">{{ $t('creted.bdzthf') }}:</div>
            <div class="Uniformmargmintop" v-show="radio1">
                <el-select v-model="Theinvasion" placeholder="20s" size="mini" @change="Theinvasionselet" style="width:300px">
                    <el-option v-for="item in selectTheinvasion" :key="item" :label="item" :value="item" />
                </el-select>
            </div>
            <div class="Uniformmargtop" v-show="radio1">
                <el-button type="primary" @click="stabilization(newtasks)" @keyup.space.prevent.native @keydown.enter.prevent.native>
                    {{ $t('creted.cj') }}
                </el-button>
            </div>
        </div>
        <el-dialog :title="$t('user.adduser')" v-model="opeatorfalge" custom-class="oneUser" @close="cancelDialog">
            <div>
                <el-input v-model="addopeatorsname" :placeholder="$t('system.password.pleaseEnter')" />
            </div>
            <div :style="{ margin: '10px 0', color: 'rgba(0,0,0,0.4)' }">
                {{ $t('user.createrdoperatoruser') }}
            </div>
            <div :style="{ textAlign: 'right' }">
                <el-button @keyup.space.prevent.native @keydown.enter.prevent.native @click="cancelDialog">{{ $t('system.cancel') }}</el-button>
                <el-button type="primary" @click="addUseropertaor" @keyup.space.prevent.native @keydown.enter.prevent.native>
                    {{ $t('system.determine') }}
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script lang="ts">
import { reactive, ref, toRefs, defineComponent, onMounted, watch, nextTick } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { debounce, throttle } from 'lodash';
import i18n from '@/locales';
import Killtime from '@/components/killtime/index.vue';
import Killtime2 from '@/components/killtime2/index.vue';
import {
    regionList,
    delRegion,
    editRegion,
    createRegion,
    regionInfo,
    roomList,
    createTasks,
    Operationtasks,
    deletetaskrw,
    currenttask,
    newroomlist,
    getmjpmac,
    robotelevator
} from '@/apis/region';
import { getAllUser, addUser } from '@/apis/user';
import internal from 'stream';
import { useStore } from '@/store';
import { ElMessage } from 'element-plus';
import { error } from 'console';
interface stateface {
    paramshttp?: Object;
    //定义点击房间后传递的字段为对象
    roomsobj: any;
    options: Array<any>;
    radio1: String;
    checked1: Boolean;
    checked2: Boolean;
    countdown: any;
    Theinvasion: any;
    selectcountdown: Array<String>;
    selectTheinvasion: Array<String>;
    num: any;
    explonum: any;
    sn: any;
    checked1falge: Boolean;
    exploresion: string | number;
    getfloor: number;
    getfloorarray: Array<number>;
    getflooron: boolean;
    mode: number;
    addoperatorflge: boolean;
    operatorname: any;
    opertorlist: Array<any>;
    opeatorfalge: boolean;
    addopeatorsname: string;
    explonum2: any;
    exploresion2: string | number;
    // count:any,
    // counts: any
}
// interface resQusts{
//   duration:Number
// }
export default defineComponent({
    components: {
        Killtime,
        Killtime2
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const states: stateface = reactive({
            sn: route.params.sn,
            paramshttp: {
                page: 1,
                pageSize: 100,
                limit: true,
                areaName: ''
            },
            value: [],
            options: [],
            roomsobj: {},
            radio1: '',
            checked1: true,
            checked2: true,
            selectcountdown: ['5s', '10s', '15s', '20s', '25s', '30s'],
            countdown: '20s',
            selectTheinvasion: ['1min', '3min', '5min', '10min'],
            explorelist: ['30s', '1min', '2min', '3min', '5min'],
            exploresion: '',
            Theinvasion: '5min',
            num: 5,
            explonum: null,
            checked1falge: false,
            getfloor: 1,
            getfloorarray: [],
            getflooron: false,
            mode: 1,
            addoperatorflge: false,
            opertorlist: [],
            operatorname: store.state.user.name == '' ? store.state.user.email : store.state.user.name,
            opeatorfalge: false,
            addopeatorsname: '',
            explonum2: null,
            exploresion2: ''
            // count: {abc:1},
            // counts: {}
            // Gotothetop() {
            //     router.go(-1)
            // }
        });
        const methods = reactive({
            // 新增操作人按钮确认
            addUseropertaor() {
                if (states.addopeatorsname.length < 6 || states.addopeatorsname.length > 20) {
                    ElMessage({
                        message: i18n.global.t('user.usernameRule2'),
                        type: 'error'
                    });
                    return;
                }
                addUser({ username: states.addopeatorsname, password: '123456' }).then((res: any) => {
                    console.log(res);
                    if (res.code == 200) {
                        states.opeatorfalge = false;
                        states.operatorname = states.addopeatorsname;
                        states.addopeatorsname = '';
                        methods.getusers();
                        ElMessage({
                            message: i18n.global.t('system.createSuccess'),
                            type: 'success'
                        });
                    } else if (res.code == 100013) {
                        ElMessage({
                            message: i18n.global.t('user.repeatUser'),
                            type: 'error'
                        });
                    }
                });
            },
            // 取消新增操作人
            cancelDialog() {
                states.opeatorfalge = false;
                states.addopeatorsname = '';
            },
            // 点击后设置操作人
            setusernames(ele: any) {
                if (ele.negative == 1) {
                    return;
                }
                states.operatorname = ele.username == '' ? ele.email : ele.username;
                states.addoperatorflge = false;
            },
            getelevator(ele: string) {
                robotelevator({
                    sn: states.sn,
                    name: ele
                }).then((res: any) => {
                    console.log(JSON.parse(res.data));
                    let ress = JSON.parse(res.data);
                    if (ele === 'ele_floor') {
                        methods.getfloorsmax(Number(ress.content.value));
                    } else {
                        states.getflooron = ress.content.value === 'on' ? true : false;
                    }
                });
            },
            godashboards() {
                router.push('/dashboard');
            },
            gotorobotsn() {
                router.push('/robot/robotInfo/' + states.sn);
            },
            killtrue(): void {
                Killtimes.value.timeflage = true;
            },
            killtrue2(): void {
                Killtimes2.value.timeflage = true;
            },
            timesvaluedurion(value1: number, value2: number) {
                states.explonum = value1 * 60 + value2;
                states.exploresion = value1 + 'min' + value2 + 'S';
                console.log(states.explonum);
            },
            timesvaluedurion2(value1: number, value2: number) {
                states.explonum2 = value1 * 60 + value2;
                states.exploresion2 = value1 + 'min' + value2 + 'S';
            },
            // 获取门禁牌是否绑定
            getchecked1falge(): void {
                getmjpmac({
                    sn: route.params.sn,
                    option: 'connected'
                }).then((res: any) => {
                    if (res.data.mac === '') {
                        states.checked1falge = true;
                        states.checked1 = false;
                    }
                });
            },
            // 获取区域及区域下的所有房间 手动添加上value 和label字段
            getquyus: (): void => {
                regionList(states.paramshttp).then((res: any) => {
                    console.log(res.data.lists);
                    states['options'] = res.data.lists;
                    states['options'].forEach((ele: any, index: number) => {
                        states['options'][index].value = states['options'][index].ID;
                        states['options'][index].label = states['options'][index].area_name;
                        newroomlist({
                            page: 0,
                            pageSize: 100,
                            sn: states.sn,
                            areaid: states['options'][index].ID
                        }).then((ress: any) => {
                            console.log(JSON.parse(ress.data));
                            states['options'][index]['children'] = [];
                            if (JSON.parse(ress.data).content.list.length > 0) {
                                JSON.parse(ress.data).content.list.forEach((eless: any, indexs: number) => {
                                    states['options'][index]['children'][indexs] = eless;
                                    states['options'][index]['children'][indexs].label = eless.name;
                                    states['options'][index]['children'][indexs].value = {
                                        roomid: eless.area_id === 0 ? eless.id : eless.yun_id,
                                        areaid: eless.area_id,
                                        mapnamas: eless.map
                                    };
                                });
                            }
                        });
                    });
                });
            },
            // 选择房间后判断
            handleChange: (value: any): void => {
                // states .roomsobj  = {ajda:1}
                if (!value[1]) {
                    ElMessage({
                        message: i18n.global.t('disinfect.dqmyfj')
                    });
                } else {
                    states.roomsobj = value[1];
                }
            },
            stabilization: debounce((Needtooffer) => Needtooffer(), 2000, {
                leading: true,
                trailing: false
            }),
            // 点击切换倒计时
            countselet: (values: string) => {
                console.log(values);
                states.countdown = values;
            },
            // 点击切换探索模式下时间
            exploresionselet: (explorevalue: string) => {
                console.log(explorevalue);
                if (parseInt(explorevalue) != 30) {
                    states.explonum = parseInt(explorevalue) * 60;
                } else {
                    states.explonum = parseInt(explorevalue);
                }
            },
            // 点击切换入侵恢复时间
            Theinvasionselet: (values: string) => {
                console.log(values);
                states.Theinvasion = values;
            },
            newtasks: () => {
                const restask: Object = {
                    mode: states.mode,
                    area_id: states.roomsobj.areaid,
                    auto_finish: parseInt(states.Theinvasion) * 60,
                    countdown: parseInt(states.countdown),
                    cycle: 'immediately',
                    days: [],
                    duration: states.radio1 === 'disinfect' ? states.explonum2 : states.radio1 === 'explore_disinfect' ? states.explonum : null,
                    flags: (states.checked1 ? 1 : 0) | (states.checked2 ? 2 : 0),
                    map_name: states.radio1 === 'deploy_disinfect' ? states.roomsobj.mapnamas : '',
                    room_id: states.roomsobj.roomid,
                    type: states.radio1,
                    time: '',
                    date: '',
                    destination: String(states.getfloor),
                    operator: states.operatorname
                };
                console.log(restask);
                createTasks(route.params.sn, restask).then((res: any) => {
                    // console.log(res)
                    Operationtasks(res.data.taskid, 'start', states.sn, {})
                        .then((ress: any) => {
                            console.log(ress);
                            if (JSON.parse(ress.data).content.code === 0) {
                                ElMessage({
                                    message: i18n.global.t('creted.cjcgzztz'),
                                    type: 'success'
                                });
                                currenttask(states.sn, {}).then((taskres: any) => {
                                    console.log(JSON.parse(taskres.data.data.statusinfo).content);
                                    if (JSON.parse(taskres.data.data.statusinfo).content.code === 0) {
                                        setTimeout(() => {
                                            router.replace(`/robot/taskInfo/${JSON.parse(taskres.data.data.statusinfo).content.report}`);
                                        }, 1000);
                                    }
                                });
                            } else if (JSON.parse(ress.data).content.code === 11010031) {
                                ElMessage({
                                    message: i18n.global.t('creted.mjpwljcjsb')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010023) {
                                ElMessage({
                                    message: i18n.global.t('creted.dqyjxrw')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010028) {
                                ElMessage({
                                    message: i18n.global.t('creted.xtlbcwcjsb')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010032) {
                                ElMessage({
                                    message: i18n.global.t('creted.sbgzcjsb')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010030) {
                                ElMessage({
                                    message: i18n.global.t('creted.jqjtqbcan')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010033) {
                                ElMessage({
                                    message: i18n.global.t('creted.dcyccjsb')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010034) {
                                ElMessage({
                                    message: i18n.global.t('creted.fztyccjsb')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010035) {
                                ElMessage({
                                    message: i18n.global.t('creted.dpdjglcjsb')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010036) {
                                ElMessage({
                                    message: i18n.global.t('creted.zdyccjsb')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010037) {
                                ElMessage({
                                    message: i18n.global.t('creted.zczcjsb')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010038) {
                                ElMessage({
                                    message: i18n.global.t('creted.xczcjsb')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010042) {
                                ElMessage({
                                    message: i18n.global.t('creted.dqdtbczwfcj')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010045 || JSON.parse(ress.data).content.code === 11011018) {
                                ElMessage({
                                    message: i18n.global.t('creted.dhmkyxzcjsb')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010046) {
                                ElMessage({
                                    message: i18n.global.t('creted.cgqyccjsb')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010048) {
                                ElMessage({
                                    message: i18n.global.t('creted.jqrwdggcjsb')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else if (JSON.parse(ress.data).content.code === 11010049) {
                                ElMessage({
                                    message: i18n.global.t('creted.jqrdlxy')
                                });
                                deletetaskrw(res.data.taskid, {});
                            } else {
                                deletetaskrw(res.data.taskid, {});
                            }
                        })
                        .catch((error: any) => {
                            deletetaskrw(res.data.taskid, {});
                        });
                });
            },
            getfloorsmax(valsa: number) {
                for (let i = 1; i <= valsa; i++) {
                    states.getfloorarray.push(i);
                }
            },
            setfloorcs(values: number) {
                states.getfloor = values;
                console.log(states.getfloor);
            },
            getusers() {
                getAllUser({
                    userName: '',
                    authority: '',
                    negative: '',
                    pageSize: 1000,
                    page: 1
                }).then((res: any) => {
                    console.log(res);
                    states.opertorlist = res.data.lists;
                });
            }
        });
        let Killtimes = ref<any | HTMLElement>(null);
        let Killtimes2 = ref<any | HTMLElement>(null);
        // watch(() => states.count, (newValue, oldValue) => {
        //   console.log(newValue, oldValue)
        //   if (newValue != states.counts) {
        //   }
        // })
        onMounted(() => {
            //调用区域
            methods.getchecked1falge();
            methods.getquyus();
            methods.getelevator('elevator');
            methods.getelevator('ele_floor');
            methods.getusers();
            // console.log(store.state.user.authority)
            // states.count = {
            //   abc:2
            // }
            // states.counts = states.count
            nextTick(() => {
                console.log(Killtimes.value.timeflage);
                states.explonum = Killtimes.value.overindex * 60 + Killtimes.value.overmiaoindex;
                states.exploresion = Killtimes.value.overindex + 'min' + Killtimes.value.overmiaoindex + 'S';

                states.explonum2 = Killtimes2.value.overindex * 60 + Killtimes2.value.overmiaoindex;
                states.exploresion2 = Killtimes2.value.overindex + 'min' + Killtimes2.value.overmiaoindex + 'S';
            });
        });
        return {
            ...toRefs(states),
            ...toRefs(methods),
            store,
            debounce,
            Killtimes,
            Killtimes2
        };
    }
});
</script>
<style lang="scss" scoped>
.robotDetail {
    position: relative;
    min-height: 50px;
    max-height: 100%;
    padding-left: 340px;
    font-size: 12px;

    .Uniformmargtop {
        margin-top: 25px;
    }

    .Uniformmargmintop {
        margin-top: 10px;

        .timesclass {
            height: 30px;
            line-height: 30px;
            width: 300px;
            padding-left: 15px;
            color: #606266;
            border-radius: 4px;
            border: 1px solid #dcdfe6;
        }
    }
}

.createstask {
    ::v-deep(.needGo) {
        span {
            color: rgba(0, 0, 0, 0.45);
            cursor: pointer;
        }
    }
}

.addlistoperator {
    width: 300px;
    height: 30px;
    line-height: 30px;
    margin-top: 10px;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    text-indent: 15px;
    position: relative;
    cursor: pointer;

    .opertorimages {
        width: 14px;
        height: 14px;
        right: 16px;
        top: 7px;
        position: absolute;
    }

    .addoperator {
        width: 300px;
        position: absolute;
        top: 30px;
        z-index: 9999;
        border: 1px solid #dcdfe6;
        background: #fff;

        .modoals {
            max-height: 200px;
            overflow-y: auto;
            border-radius: 4px;
            z-index: 9999;

            .boxdiv {
                height: 36px;
                line-height: 36px;
            }
        }

        .addbtn {
            height: 36px;
            line-height: 36px;
        }
    }

    .arrowTransform {
        transition: 0.2s;
        transform-origin: center;
        transform: rotateZ(-180deg);
    }

    .arrowTransformReturn {
        transition: 0.2s;
        transform-origin: center;
        transform: rotateZ(0deg);
    }
}
</style>
