
import { reactive, ref, toRefs, defineComponent, onMounted, watch, nextTick } from "vue";
interface timesdurion {
    timesnewfen: Array<any>;
    timesnewmiao: Array<any>;
    timeflage: boolean;
    overindex: any,
    overmiaoindex: any,
    defaultValuefen: null | number;
    defaultValuemiao: null | number;
}
import { ElMessage } from 'element-plus'
import i18n from "@/locales";
const _t = i18n.global.t;
export default defineComponent({
    name:'Killtime2',
    emits: ['timesvaluedurion2'],
    setup(prop, content) {
        let timelefts = ref<any | HTMLElement>(null)
        let timerights = ref<any | HTMLElement>(null)
        const timedurion: timesdurion = reactive({
            timesnewfen: [],
            timesnewmiao: [],
            timeflage: false,
            overindex: 1,
            overmiaoindex: 0,
            defaultValuefen: 1,
            defaultValuemiao: 0
        })
        const methods = reactive({
            overs: (item: number | string, index: number) => {
                timedurion.overindex = index
            },
            oversmiao: (item: number | string, index: number) => {
                timedurion.overmiaoindex = index
            },
            cancels() {
                timedurion.timeflage = false
                timedurion.overindex = timedurion.defaultValuefen
                timedurion.overmiaoindex = timedurion.defaultValuemiao
            },
            comfrims() {
                if (timedurion.overindex === 0 && timedurion.overmiaoindex === 0) {
                    ElMessage({
                        message: _t('disinfect.xssjbwl')
                    })
                    return
                }
                timedurion.timeflage = false
                content.emit('timesvaluedurion2', timedurion.overindex, timedurion.overmiaoindex)
                timedurion.defaultValuefen = timedurion.overindex
                timedurion.defaultValuemiao = timedurion.overmiaoindex
            },
            setleftscrlotop(values: number) {
                timelefts.value.scrollTop = (20 * values) - 40
            },
            setrightscrlotop(values: number) {
                timerights.value.scrollTop = (20 * values) - 40
            }
        })
        onMounted(() => {
            for (let i = 0; i < 60; i++) {
                timedurion.timesnewfen.push(i < 10 ? '0' + i : i)
            }
            for (let ii = 0; ii < 60; ii++) {
                timedurion.timesnewmiao.push(ii < 10 ? '0' + ii : ii)
            }
            nextTick(() => {
                watch(
                    timedurion, 
                    (newold: any) => {
                        methods.setleftscrlotop(newold.overindex)
                        methods.setrightscrlotop(newold.overmiaoindex)
                    },
                    { deep: true },
                )
            })
        })
        return {
            ...toRefs(timedurion),
            ...toRefs(methods),
            timelefts,
            timerights
        }
    }
})
